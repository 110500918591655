.App {
  font-family: 'Cormorant';
}

.App-header {
  background-color: #8a9ba2;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 2rem;
}

.App-body {
  background-color: #8a9ba2;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 2rem;
}

.App-link {
  color: white;
}

.button {
  background: #FFF00;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  border-radius: 8px;
  padding: 13px 23px;
  border: 1px solid #222222;
  transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
  :hover {
      border-color: #008cba;
      background: #f7f7f7;
  }
}

.button:disabled {
  opacity: 0.5;
}

.wrapper,
html,
body {
  height: 100%;
  margin: 50;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.input-form {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.rsvp-form {
  display: flex;
  min-width: 100%;
  margin: 10px;
}

.form-center-text {
  display: flex;
  text-align: center;
}

.footer-controls {
    display: flex;
}

.footer-controls a {
    width: 100%;
    max-width: 200px;
    padding: 10px 5px;
    border: 1px solid #2c2c2c;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    color: #2c2c2c;
    transition: all 0.3s ease;
}

.footer-controls .prev-button {
    margin-top: 10px;
    margin-right: auto;
    margin-left: 10px;
    justify-content: start;
}

.footer-controls .next-button {
    margin-top: 10px;
    margin-left: auto;
    margin-right: 10px;
    justify-content: end;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px 5px;
}

.display-name {
  padding: 10px 5px;
}

.form-box {
  max-width: 1400px;
  min-width: 55%;
}

.pepper-no-result {
  width: auto;
  max-height: 50%;
}

.rsvp-box {
  border: 2px solid #ffffff;
  justify-content: space-between;
  border-radius: 10pt;
  padding: 10px 5px;
  margin: 10px;
  background-color: white;
  color: black;
}

.multi-result-box {
  border: 2px solid #ffffff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10pt;
  margin: 10px;
  padding: 10px 5px;
  min-width: 55%;
  background-color: white;
  color: black;
}

.multi-result-box:hover {
   background-color: lightblue;
}

.success-text {
  text-align: center;
  vertical-align: middle;
}

.input.rounded {
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.dropdown {
  margin: 5px;
}
